// CSS is imported and bundled via Eleventy Vite configuration - see .eleventy.js and _11ty/getViteOptions.js.
// import '/assets/css/styles.scss';

import checkFlexGap from "./modules/checkFlexGap";
import sectionMenu from "./modules/sectionMenuDataAttrs2";
// import calendlyPopup from "./modules/calendly";
import "glider-js";
import gliderJSinit from "./modules/gliderInit";
import netlifyForm from "./modules/form";
import addButtonAnimation from "./modules/addButtonAnimation";
import netlifyEndScript from "./modules/netlifyEndScript";

// Check if flex-gap is supported
// Apply appropriate class to the <html> element based on flexbox-gap support
if (checkFlexGap()) {
  document.documentElement.classList.add("flexbox-gap");
} else {
  document.documentElement.classList.add("no-flexbox-gap");
}

// Run scripts
sectionMenu();
// calendlyPopup();
gliderJSinit();
netlifyForm();

if (document.getElementById("formContact")) {
  addButtonAnimation('.form-contact button[type="submit"]', "ripple");
}

netlifyEndScript();
