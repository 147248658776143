// Debounce delays the display of error message until the user stops typing for a certain amount of time.
import { debounce } from "./helpers.js"; // adjust the path as needed

/**
 * Handles client-side validation and submission of a contact form,
 * and displays feedback to users via an overlay.
 *
 * @module netlifyForm
 */
import validate from "validate.js";
import { showOverlayMessage } from "./overlay.js"; // adjust the path as needed

/**
 * Validates and submits the form, then provides feedback using an overlay.
 * Only works if the contact form is present on the page.
 */
export default function netlifyForm() {
  // Check if the contact form is present on the page
  if (document.getElementById("formContact")) {
    const messageParagraph = document.querySelector(
      ".contact-submit-message p"
    );
    const messageContainer = document.querySelector(".contact-submit-message");
    const iconError = `<span class="sr-only">Error: </span><span class="form-contact__icon-span"><svg class="icon" width="24" height="24">
                <use xlink:href="#icon-error" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
              </svg></span>`;
    const iconCorrect = `<span class="sr-only">Success: </span><span class="form-contact__icon-span"><svg class="icon" width="24" height="24">
                <use xlink:href="#icon-correct" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
              </svg></span>`;
    const messageSuccess =
      `Thank you for your message. ${iconCorrect}<br>I'll get back to you as soon as possible.`;
    const messageFail =
      `Sorry, there was a problem submitting your message. Please try again. ${iconError}`;
    const overlaySuccess = `Your message was sent! ${iconCorrect}`;
    const overlayFail = `Your message didn't get sent! ${iconError}`;

    const inputName = document.getElementById("name");
    const inputEmail = document.getElementById("email");
    const textAreaMessage = document.getElementById("message");

    const errorName = document.getElementById("error-name");
    const errorEmail = document.getElementById("error-email");
    const errorMessage = document.getElementById("error-message");

    // Clears messages when any form element receives focus
    [inputName, inputEmail, textAreaMessage].forEach((element) => {
      element.addEventListener("focus", () => {
        if (messageParagraph.innerText !== "") {
          messageParagraph.innerText = "";
          messageContainer.classList.remove("contact-submit-message--reveal");
        }
      });
    });

    // Define an array of fields with their associated error elements
    const fields = [
      { input: inputName, errorElem: errorName, errorKey: "name" },
      { input: inputEmail, errorElem: errorEmail, errorKey: "email" },
      { input: textAreaMessage, errorElem: errorMessage, errorKey: "message" },
    ];

    const constraints = {
      name: {
        presence: true,
        length: {
          minimum: 3,
          message: "must be at least 3 characters",
        },
      },
      email: {
        presence: true,
        email: {
          message: "must be a valid email",
        },
      },
      message: {
        presence: true,
        length: {
          minimum: 20,
          message: " - over 20 characters please",
        },
      },
    };

    /**
     * Converts a FormData object into a regular object.
     *
     * @param {FormData} formData - The FormData object to convert.
     * @returns {Object} - The converted object.
     */
    function formDataToObject(formData) {
      let obj = {};
      formData.forEach((value, key) => {
        obj[key] = value;
      });
      return obj;
    }

    /**
     * Validates an individual form field based on pre-defined constraints.
     *
     * @param {Object} field - Field definition with input, errorElem, and errorKey.
     * @returns {boolean} - True if field is valid, false otherwise.
     */
    const validateField = (field) => {
      const value = {};
      value[field.errorKey] = field.input.value;
      const fieldErrors = validate(value, {
        [field.errorKey]: constraints[field.errorKey],
      });

      if (fieldErrors && fieldErrors[field.errorKey]) {
        const errorText = `${iconError} <span class="form-contact__text-span">${fieldErrors[field.errorKey][0]}</span>`;
        field.errorElem.innerHTML = errorText;
        field.input.classList.add("error-field");
        return false; // field has an error
      } else {
        field.errorElem.innerHTML = ""; // Clear the error message
        field.input.classList.remove("error-field");
        return true; // field is valid
      }
    };
    
    fields.forEach((field) => {
      const debouncedValidate = debounce(() => validateField(field), 800);
    
      field.input.addEventListener("keydown", debouncedValidate);
      // Apply debouncing for the input event as well
      field.input.addEventListener("input", debouncedValidate);
    }); 
       
    /**
     * Handles the form submission event. If all fields are valid, it sends the
     * form data. Depending on the success of the submission, it displays an
     * appropriate overlay message.
     *
     * @param {Event} event - The form submission event.
     */
    const handleSubmit = (event) => {
      event.preventDefault();

      // Using the validateField function to validate each field
      const areAllFieldsValid = fields.every(validateField);

      if (!areAllFieldsValid) return;

      const myForm = event.target;
      const formData = new FormData(myForm);

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          // Only if response is okay, then attempt to read the body
          return response.text();
        })
        .then((text) => {
          myForm.reset();

          // Show the success message on the overlay
          showOverlayMessage(overlaySuccess);

          messageParagraph.innerHTML = messageSuccess; // Use innerHTML
          messageContainer.classList.add("contact-submit-message--reveal");
        })
        .catch((error) => {
          // Show the failure message on the overlay
          showOverlayMessage(overlayFail);

          messageParagraph.innerHTML = messageFail; // Use innerHTML
          messageContainer.classList.add("contact-submit-message--reveal");
        });
    };

    // Attach the form submission handler
    document
      .getElementById("formContact")
      .addEventListener("submit", handleSubmit);
  }
}
