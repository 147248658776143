/**
 * Creates a debounced version of a function, delaying its invocation until after a specified wait time
 * has passed since the last time the debounced function was invoked.
 *
 * @function
 * @param {Function} func - The function to debounce.
 * @param {number} delay - The number of milliseconds to delay.
 * @returns {Function} Returns the debounced function.
 * @example
 *
 * const myFunction = () => console.log('Function called');
 * const debouncedMyFunction = debounce(myFunction, 300);
 * debouncedMyFunction(); // Calls `myFunction` after 300ms if `debouncedMyFunction` isn't invoked again during this period.
 */
function debounce(func, delay) {
  let debounceTimer;
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}

/**
 * Throttle function to limit the frequency of function calls.
 *
 * @param {Function} func - The function to throttle.
 * @param {number} wait - The number of milliseconds to throttle executions to.
 * @returns {Function} - The throttled function.
 */
function throttle(func, wait) {
  let context, args, result;
  let timeout = null;
  let previous = 0;

  const later = function () {
    previous = new Date().getTime();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };

  return function () {
    const now = new Date().getTime();
    if (!previous) previous = now;
    const remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

/**
 * Returns the appropriate animation end event name for the current browser.
 *
 * This function determines which animation end event is supported by the
 * browser by checking the existence of animation-related properties on
 * a test element's style.
 *
 * @returns {string} The name of the supported animation end event. For example,
 * 'animationend' or 'webkitAnimationEnd'.
 */
function getAnimationEndEvent() {
  const el = document.createElement("testElement");
  const animations = {
    animation: "animationend",
    OAnimation: "oAnimationEnd",
    MozAnimation: "animationend",
    WebkitAnimation: "webkitAnimationEnd",
  };

  for (let t in animations) {
    if (el.style[t] !== undefined) {
      return animations[t];
    }
  }
}

/**
 * Returns the appropriate transition end event name for the current browser.
 * 
 * This function determines which transition end event is supported by the
 * browser by checking the existence of transition-related properties on
 * a test element's style.
 *
 * @returns {string} The name of the supported transition end event. For example, 
 * 'transitionend' or 'webkitTransitionEnd'.
 */
function getTransitionEndEvent() {
  const el = document.createElement('testElement');
  const transitions = {
    'transition': 'transitionend',
    'OTransition': 'oTransitionEnd',
    'MozTransition': 'transitionend',
    'WebkitTransition': 'webkitTransitionEnd'
  };

  for (let t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
}

/**
 * @param {HTMLElement} containerElement - The container where the element should be appended or inserted.
 * @param {HTMLElement} targetElement - The element to move.
 * @param {HTMLElement} referenceElement - The element before which targetElement is inserted (for mobile layout).
 * @param {string} breakpoint - The CSS variable containing the width breakpoint.
 */
function rearrangeElementBasedOnBreakpoint(containerElement, targetElement, referenceElement, breakpoint) {
  if (window.innerWidth > parseInt(breakpoint)) {
    // Move the targetElement to the end of the container for desktop
    containerElement.appendChild(targetElement);
  } else {
    // Place the targetElement back to its original position for mobile
    containerElement.insertBefore(targetElement, referenceElement.nextElementSibling);
  }
}

/**
 * Listens for focus events on the specified element and its descendants,
 * and toggles the given class name on the closest ancestor matching the given selector.
 * This acts as a workaround for the lack of `focus-within` support.
 * Older Safari versions don't support it.
 * 
 * @param {string} selector - The CSS selector for the target ancestor element.
 * @param {string} focusClass - The class name to toggle based on focus state.
 */
function addFocusClassToElement(selector, focusClass) {
  var element = document.querySelector(selector);

  // Return early if no element is found
  if (!element) return;

  /**
   * Adds the specified focus class to the closest ancestor element
   * matching the selector when one of its descendants gains focus.
   */
  element.addEventListener('focusin', function(event) {
    const closestAncestor = event.target.closest(selector);
    if (closestAncestor) {
      closestAncestor.classList.add(focusClass);
    }
  });

  /**
   * Removes the specified focus class from the closest ancestor element
   * matching the selector when one of its descendants loses focus.
   */
  element.addEventListener('focusout', function(event) {
    const closestAncestor = event.target.closest(selector);
    if (closestAncestor) {
      closestAncestor.classList.remove(focusClass);
    }
  });
}

// Usage:
// addFocusClassToElement('.skip-link', 'has-focus');


/**
 * Examines a URL or path for a trailing slash and removes it if present.
 * 
 * @param {string} str - The URL or path to examine.
 */

function stripTrailingSlash(str) {
  return str.endsWith('/') ?
      str.slice(0, -1) :
      str;
};


export { debounce, throttle, getAnimationEndEvent, getTransitionEndEvent, rearrangeElementBasedOnBreakpoint, addFocusClassToElement, stripTrailingSlash };
