/**
 * Function to add an animation effect on the given button element.
 * @param {string} btnSelector - The CSS selector for the button element.
 * @param {string} animationClass - The class to add for animation effects.
 */
function addButtonAnimation(btnSelector, animationClass) {
    const btnElement = document.querySelector(btnSelector);

    // If no button element is found, exit early
    if (!btnElement) {
        console.warn(`No element found with selector: ${btnSelector}`);
        return;
    }

    /**
     * Handler for the animation effect.
     * @param {Event} e - The button click event.
     */
    const animationHandler = function (e) {
        let xPos = e.offsetX;
        let yPos = e.offsetY;
        let btnPseudoHeight = btnElement.offsetWidth;
        btnElement.style.setProperty("--pseudo-height", `${btnPseudoHeight}px`);
        btnElement.style.setProperty("--xpos", `${xPos}px`);
        btnElement.style.setProperty("--ypos", `${yPos}px`);
    };

    btnElement.addEventListener("click", function (e) {
        animationHandler(e);
        btnElement.classList.add(animationClass);
    });

    btnElement.addEventListener("animationend", function () {
        btnElement.classList.remove(animationClass);
    });
}

export default addButtonAnimation;
