/**
 * Display an overlay with a given message. The overlay can auto-hide after a
 * specified duration if provided.
 *
 * This function displays an overlay message to the user. The overlay will close
 * if the user clicks on the close button, clicks outside the message (but still
 * on the overlay), presses the escape key, or after the specified duration (if provided).
 * Event listeners are removed after overlay closure to prevent potential memory leaks.
 * Once the overlay is closed, the focus is returned to the element that was focused 
 * before the overlay was shown or to the provided returnFocusElement.
 *
 * @param {string} message - The message to display in the overlay.
 * @param {number} [duration] - Optional. The duration to display the overlay in milliseconds.
 * If not provided, the overlay won't auto-close.
 * @param {HTMLElement} [returnFocusElement] - Optional. The element to return focus to once the overlay is closed.
 * If not provided, focus returns to the previously active element.
 * @returns {void}
 */
export function showOverlayMessage(message, duration, returnFocusElement = null) {
  /** @type {HTMLElement} */
  const body = document.body;
  /** @type {HTMLElement} */
  const overlay = document.querySelector(".overlay");
  /** @type {HTMLElement} */
  const messageSpan = document.getElementById("messageSpan");
  /** @type {HTMLElement} */
  const closeButton = document.getElementById("close");

  // Store the previously focused element to return focus to it later. 
  const previouslyFocusedElement = document.activeElement || returnFocusElement;

  // Set aria-expanded to true on the previously focused element.
  // Set aria-expanded to true on the previously focused element.
  if (previouslyFocusedElement) {
    previouslyFocusedElement.setAttribute("aria-expanded", "true");
  }

  // Set the message inside the span.
  messageSpan.innerHTML = message;

  // Show the overlay and disable scrolling on body.
  overlay.style.display = "flex";
  overlay.focus();
  body.style.overflow = "hidden"; // Disable scrolling

  /**
   * Listener to handle the end of an animation.
   * @param {AnimationEvent} event - The DOM animationend event.
   * @returns {void}
   */
  const onAnimationEnd = (event) => {
    if (event.animationName === "fade-out") {
      overlay.style.display = "none";
      body.style.overflow = "auto"; // Enable scrolling
      overlay.classList.remove("fade-out"); // Remove fade-out class for future use
      messageSpan.innerHTML = ""; // Clear the message
      previouslyFocusedElement.setAttribute("aria-expanded", "false");
      overlay.removeEventListener("animationend", onAnimationEnd); // Remove the event listener once done
      document.removeEventListener("keydown", escapeKeyListener); // Remove the event listener once done

      // Return focus to the previously focused element.
      if (previouslyFocusedElement) {
        previouslyFocusedElement.focus();
      }
    }
  };

  /**
   * Hide the overlay, clear the hide timeout if set, and remove event listeners.
   * @returns {void}
   */
  const hideOverlay = () => {
    // Apply fade-out animation
    overlay.classList.add("fade-out");
    overlay.addEventListener("animationend", onAnimationEnd);

    if (hideTimeout) {
      clearTimeout(hideTimeout);
    }
    document.removeEventListener("click", outsideClickListener);
    closeButton.removeEventListener("click", closeButtonListener);
  };

  /** @type {number | undefined} */
  let hideTimeout;
  if (duration) {
    // Set a timeout to auto-hide the overlay if duration is provided.
    hideTimeout = setTimeout(() => {
      hideOverlay();
    }, duration);
  }

  /**
   * Listener for detecting clicks outside the overlay message or on the overlay/close button.
   * @param {Event} event - The DOM click event.
   * @returns {void}
   */
  const outsideClickListener = (event) => {
    // Check if the event target is the SVG or a descendant of it.
    const isInsideSVGCloseButton = event.target.closest("#overlayCloseIcon");

    // Check if the event target is the close button or a descendant of it.
    const isCloseButton = event.target.closest("#btnClose");

    if (
      !messageSpan.contains(event.target) &&
      (event.target === overlay || isCloseButton || isInsideSVGCloseButton)
    ) {
      hideOverlay();
    }
  };

  /**
   * Listener for detecting clicks on the close button.
   * This might be redundant now that we're checking for the close button in the outsideClickListener,
   * but it's kept here for clarity.
   * @returns {void}
   */
  const closeButtonListener = () => {
    hideOverlay();
  };

  // Attach event listeners to detect outside clicks and close button clicks.
  document.addEventListener("click", outsideClickListener);
  closeButton.addEventListener("click", closeButtonListener);

  const escapeKeyListener = (event) => {
    if (event.key === "Escape") {
      hideOverlay();
    }
  };

  document.addEventListener("keydown", escapeKeyListener);
}
