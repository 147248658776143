import {
  throttle,
  getTransitionEndEvent,
  rearrangeElementBasedOnBreakpoint,
} from "./helpers.js";

// will add vendor prefixes if necessary
const transitionEndEvent = getTransitionEndEvent();

export default function sectionMenu() {
  /**
   * This script is used for a page with a section menu.
   * It contains code to handle menu toggle, section navigation, and scroll observations.
   */
  if (document.querySelector(".has-section-menu")) {
    /**
     * DOM elements.
     */
    const sections = document.querySelectorAll("section[data-section]");
    const pageNav = document.querySelector("[data-page-nav]");
    const pageNavContainerDiv = pageNav.querySelector(".flex.container");
    const pageNavHomeLink = document.querySelector("[data-page-nav-home-link]");
    const pageNavList = document.querySelector("[data-page-nav-list]");
    const pageNavLinks = pageNavList.querySelectorAll("[data-page-nav-link]");
    const dotNav = document.querySelector("[data-dot-nav]");
    const menuToggle = document.querySelector("[data-menu-toggle]");
    const topLink = document.querySelector("[data-top-link]");
    const mainEle = document.querySelector("[data-main]");
    const rootStyle = getComputedStyle(document.documentElement);
    const cssVarValue = rootStyle.getPropertyValue("--menuBreakpoint").trim();

    // to set a custom property value in JavaScript:
    // document.documentElement.style.setProperty('--menuBreakpoint', '768px');

    // Convert to integer and check for NaN, then fallback to 768 if necessary.
    // In case someone accidently changes the value to something invalid or removes the custom property in the style sheet.
    const cssVarMenuBreakPoint = isNaN(parseInt(cssVarValue, 10))
      ? 768
      : parseInt(cssVarValue, 10);

    /**
     * Constant that sets delay for throttling.
     */
    const throttleDelay = 250;

    /**
     * Constant that sets delay for debouncing.
     */
    // const debounceDelay = 300;

    // Call the function on page load
    // Use the function to rearrange the `#pageNav #top` element on load
    // This is done because the top link in the nav appears before the menu on the mobile layout.
    // The top link appears after the menu on the desktop layout.
    // I want the link to get a focus outline in the correct tabbing order for keyboard users who are not using a mouse.
    // This is done by moving the link to the end of the container on desktop and back to its original position on mobile.
    rearrangeElementBasedOnBreakpoint(
      pageNavContainerDiv,
      topLink,
      pageNavHomeLink,
      cssVarMenuBreakPoint
    );

    let lastViewportHeight = window.innerHeight;

    const hasAddressBarChanged = () => {
      const currentViewportHeight = window.innerHeight;
      const difference = currentViewportHeight - lastViewportHeight;

      // This threshold (e.g., 50px) should roughly correspond to the height of the address bar.
      // It might need adjustments based on real-world behavior.
      if (Math.abs(difference) > 40) {
        lastViewportHeight = currentViewportHeight;
        return true;
      }
      return false;
    };

    /**
     * Function to return the list item containing a given link.
     * @param {HTMLElement} linkEle - Link inside list item
     * @returns {HTMLElement} - Closest parent list item
     */
    const getListItem = (linkEle) => linkEle.closest("li");

    /**
     * Function to return the link item associated with a given section.
     * @param {HTMLElement} sectionEle - Section for which to find associated link
     * @param {string} navType - Type of navigation ("page" or "dot")
     * @returns {HTMLElement} - Link associated with section
     */
    const getLinkItem = (sectionEle, navType) => {
      return document.querySelector(
        `[data-${navType}-nav-section='${sectionEle.dataset.section}']`
      );
    };

    /**
     * Function to return the vertical offset for the root of the page.
     * @returns {number} - Vertical offset from top of page
     */
    const getRootVerticalOffset = () =>
      Math.round(window.innerHeight - pageNav.offsetHeight);

    /**
     * Function to add or remove the current class from a navigation item.
     * Iterates over both "page" and "dot" navigation types.
     *
     * @param {IntersectionObserverEntry} entry - Intersection observer entry
     * @param {"add"|"remove"} action - The action to be performed on the classList of the list item
     */
    const updateNavItem = (entry, action) => {
      ["page", "dot"].forEach((navType) => {
        const linkItem = getLinkItem(entry.target, navType);
        const listItem = getListItem(linkItem);
        listItem.classList[action](`${navType}-nav__item--current`);
      });
    };

    /**
     * Function to toggle navigation items based on intersection entries.
     * It iterates over all entries and adds or removes the 'current' class to the
     * navigation item associated with each entry based on whether it is intersecting or not.
     *
     * @param {IntersectionObserverEntry[]} entries - Array of intersection observer entries
     */
    const toggleNavItem = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          updateNavItem(entry, "add");
        } else {
          updateNavItem(entry, "remove");
        }
      });
    };

    /**
     * Creating an Intersection Observer to observe sections for intersection changes.
     * The observer calls the `toggleNavItem` function whenever a section becomes visible or hidden.
     *
     * The observer options are:
     * - `root`: The element that is used as the viewport for checking visibility of the target.
     *           In this case, it's the viewport.
     * - `threshold`: Indicates at what percentage of the target's visibility the observer's callback should be executed.
     *                A threshold of 0 means the callback will run whenever the target starts or stops intersecting the root.
     * - `rootMargin`: Margin around the root. Can have values similar to the CSS margin property.
     *                 The values serve to grow or shrink each side of the root element's bounding box before computing intersections.
     */
    const sectionsObserver = new IntersectionObserver(toggleNavItem, {
      // root: document, // entire document is the root
      root: null, // viewport is the root
      threshold: 0, // threshold of 0 means the callback will run whenever the target starts or stops intersecting the root
      rootMargin: "-50% 0% -50% 0%", // margin around the root - 50% from top and bottom - effectively a line in the middle of the viewport
    });

    // begin observing each section
    sections.forEach((section) => sectionsObserver.observe(section));

    /**
     * Creates and returns a new Intersection Observer that watches the main element.
     * When the main element intersects with the viewport (as defined by rootMargin),
     * it updates the pageNav's CSS classes to toggle between "tall" and "short" states.
     *
     * If the main element is intersecting with the viewport (taking into account the rootMargin),
     * the "tall" class is removed and the "short" class is added to the pageNav.
     *
     * Conversely, if the main element is not intersecting, the "short" class is removed
     * and the "tall" class is added to the pageNav.
     *
     * The rootMargin for this Intersection Observer is dynamically calculated by the getRootVerticalOffset function.
     * This means the Intersection Observer's root margin will adjust according to the height of the pageNav.
     *
     * Note: The Intersection Observer's callback is triggered every time the main element crosses the boundary
     * defined by the rootMargin, either by scrolling up or down.
     */
    const setMainObserver = () => {
      const navHeight = pageNav.offsetHeight;
      // Won't work in Firefox mobile if address bar auto-hides and navHeight is not added to
      // rootMargin's bottom value.
      const rootMarginValue = `0px 0px ${(getRootVerticalOffset() * -1) + navHeight}px 0px`;
      
      return new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              pageNav.classList.remove("page-nav--tall");
              pageNav.classList.add("page-nav--short");
              // When the main element is intersecting (navigation is short), topLink should be focusable
              topLink.setAttribute("tabindex", "0");
              // updateTabindexBasedOnViewport();
            } else {
              pageNav.classList.remove("page-nav--short");
              pageNav.classList.add("page-nav--tall");
              // When the main element is not intersecting (navigation is tall), topLink shouldn't be focusable
              topLink.setAttribute("tabindex", "-1");
              // updateTabindexBasedOnViewport();
            }
          });
        },
        {
          root: null,
          threshold: 0,
          rootMargin: rootMarginValue,
        }
      );
    };

    /**
     * Initializes the Intersection Observer for the main element on the page.
     * `setMainObserver()` creates the Intersection Observer and returns it.
     * The Observer is then instructed to start observing the `mainEle` (the main element of the page).
     * This sets up the mechanism to change the appearance of the navigation bar when the main element intersects
     * with a certain area of the viewport.
     */
    let mainObserver = setMainObserver();
    mainObserver.observe(mainEle);

    /**
     * Updates the main Intersection Observer and rearranges DOM elements based on viewport size.
     *
     * This function performs the following tasks:
     * 1. Disconnects the existing Intersection Observer.
     * 2. Re-creates and starts observing the `mainEle`. This essentially recalibrates the observer.
     * 3. Rearranges the position of the `#pageNav #top` link based on the viewport size.
     *    The link is moved to the end of the container on desktop views and back to its original position on mobile views.
     *
     * To prevent excessive calls which could lead to performance issues, a throttling mechanism is used.
     * Once the function is invoked, subsequent calls are throttled until the `throttleDelay` time has passed.
     */
    const updateMainObserver = () => {
      mainObserver.disconnect();
      mainObserver = setMainObserver();
      mainObserver.observe(mainEle);

      // Update elements' positions based on viewport size
      rearrangeElementBasedOnBreakpoint(
        pageNavContainerDiv,
        topLink,
        pageNavHomeLink,
        cssVarMenuBreakPoint
      );
    };

    const updateMainObserverThrottled = throttle(
      updateMainObserver,
      throttleDelay
    );

    // const updateMainObserverDebounced = debounce(
    //   updateMainObserver,
    //   debounceDelay
    // );
    
    // Had problems with the Intersection Observer not updating when the address bar was hidden or shown in Firefox mobile.

    // const handleScrollThrottled = throttle(() => {
    //   if (hasAddressBarChanged()) {
    //     console.log('navbar height has changed');
    //     updateMainObserverThrottled();
    //   }
    // }, throttleDelay);

    // const handleScrollDebounced = debounce(() => {
    //   if (hasAddressBarChanged()) {
    //     console.log('navbar height has changed');
    //     updateMainObserverDebounced();
    //   }
    // }, debounceDelay);
    
    // window.addEventListener("scroll", handleScrollDebounced);

    // Add event listener to window to update the main Intersection Observer when the window is resized.
    window.addEventListener("resize", updateMainObserverThrottled);

    /**
     * Toggles the menu state between open and closed.
     *
     * @param {string} state - The desired state of the menu ("open" or "closed").
     *
     * This function modifies the class list of `pageNav` and the `aria-expanded` attribute of `menuToggle` based on the provided state.
     * If the desired state is "open", it will add the "open" class and set `aria-expanded` to "true".
     * Conversely, if the desired state is "closed", it will add the "closed" class and set `aria-expanded` to "false".
     * In both cases, it ensures that the opposite state class is removed.
     */
    const toggleMenuState = (state) => {
      const action = state === "open" ? "add" : "remove";
      pageNav.classList.remove(
        `page-nav--${state === "open" ? "closed" : "open"}`
      );
      pageNav.classList.add(`page-nav--${state}`);
      menuToggle.setAttribute(
        "aria-expanded",
        state === "open" ? "true" : "false"
      );
    };

    /**
     * Opens the menu.
     *
     * It uses the `toggleMenuState` function to switch the menu to the "open" state.
     * Also, it removes the "page-nav--list-off-page" class from `pageNav`,
     * making the menu list visible within the page layout.
     */
    const openMenu = () => {
      toggleMenuState("open");
      pageNav.classList.remove("page-nav--list-off-page");
    };

    /**
     * Closes the menu.
     *
     * It uses the `toggleMenuState` function to switch the menu to the "closed" state.
     * Note: The "page-nav--list-off-page" class is not added back here.
     * This should be done elsewhere when necessary to hide the menu list from the page layout.
     */
    const closeMenu = () => {
      pageNav.classList.add("page-nav--closing");
      toggleMenuState("closed");
    };

    /**
     * Checks if `pageNav` contains a class that indicates a certain menu state.
     *
     * @param {string} state - The state to check for.
     * @returns {boolean} - Whether `pageNav` contains a class for the specified state.
     */
    const checkMenuState = (state) =>
      pageNav.classList.contains(`page-nav--${state}`);

    /**
     * Checks if the menu is currently open.
     *
     * @returns {boolean} - Whether the menu is open.
     */
    const isMenuOpen = () => checkMenuState("open");

    /**
     * Checks if the menu is currently closing.
     *
     * @returns {boolean} - Whether the menu is closing.
     */
    const isMenuClosing = () => checkMenuState("closing");

    /**
     * Checks if the menu is currently closed.
     *
     * @returns {boolean} - Whether the menu is closed.
     */
    const isMenuClosed = () => checkMenuState("closed");

    /**
     * Checks if the `pageNav` list is the active element or contains the active element.
     *
     * @returns {boolean} - Whether the `pageNav` list is in focus.
     */
    const isPageNavInFocus = () => pageNavList.contains(document.activeElement);

    /**
     * Checks if the menu list is off the page.
     *
     * @returns {boolean} - Whether the menu list is off the page.
     */
    const isMenuListOffPage = () => checkMenuState("list-off-page");

    // If the menu is open and a click occurs outside the menu, the menu is closed.
    document.addEventListener("click", (e) => {
      if (isMenuOpen() && !pageNavList.contains(e.target)) {
        closeMenu();
      }
    });

    // Toggles the menu open or closed when the menu button is clicked.
    // If the menu is in the process of closing and the menu button is clicked, the menu is stopped from closing.
    menuToggle.addEventListener("click", (e) => {
      e.stopPropagation();

      if (menuToggle.contains(e.target) && isMenuClosing()) {
        pageNav.classList.remove("page-nav--closing");
      }
      
      if (menuToggle.contains(e.target)) {
        isMenuOpen() ? closeMenu() : openMenu();
      }
    });

    // If the menu is closed and focus moves into the menu, the menu is opened.
    document.addEventListener("focusin", (e) => {
      if (isMenuClosed() && e.target.closest(".page-nav__list")) {
        openMenu();
      }
    });

    // If the menu is open and focus moves out of the menu due to a Tab keypress, the menu is closed.
    document.addEventListener("keyup", (e) => {
      if (e.key === "Tab" && !isPageNavInFocus()) {
        if (isMenuOpen()) {
          closeMenu();
        }
      }
    });

    // If the menu is open and the Escape key is pressed, the menu is closed and focus is moved to the menu button.
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape" && isPageNavInFocus()) {
        if (isMenuOpen()) {
          closeMenu();
          menuToggle.focus();
        }
      }
    });

    // When the menu finishes its animation, if it is not open, it is moved off the page.
    // I don't want the items to be clickable when the menu is closed, so I move the menu off the page.
    // clip-path will prevent the items from being clickable so maybe this is not necessary.
    // I'll leave it in for now - maybe I'll need it later if I decide not to use the clip-path transition.
    pageNavList.addEventListener(transitionEndEvent, (e) => {
      if (e.target === pageNavList && !isMenuOpen()) {
        pageNav.classList.remove("page-nav--closing");
        pageNav.classList.add("page-nav--closed", "page-nav--list-off-page");
        menuToggle.setAttribute("aria-expanded", "false");
      }
    });

    // Reset the menu state based on viewport width.
    // If the viewport width is less than 768px and the menu is not open, move the menu off the page.
    // If the viewport width is greater than 767px, remove all menu state classes.
    const resetMenu = (e) => {
      const isMaxWidth = e.matches;
      if (isMaxWidth && !isMenuOpen()) {
        pageNav.classList.add("page-nav--list-off-page", "page-nav--closed");
        return;
      }

      pageNav.classList.remove(
        "page-nav--open",
        "page-nav--closed",
        "page-nav--list-off-page",
        "page-nav--closing"
      );
    };

    // Monitor the viewport width for changes.
    // the menuBreakPoint media query list is used to determine when the viewport width is less than 768px.
    // cssVarMenuBreakPoint is a CSS custom variable that is set to the value of the menu breakpoint.
    // We need to subtract 1 from the breakpoint value because the media query is max-width, not max-width: 767px.
    const menuBreakPoint = window.matchMedia(
      `(max-width: ${cssVarMenuBreakPoint - 1}px)`
    );
    resetMenu(menuBreakPoint);

    // This block attempts to add an event listener to the 'menuBreakPoint' media query list.
    // The standard way to add event listeners in modern browsers is to use 'addEventListener'.
    // However, in Safari, support for 'addEventListener' on media query list objects was only added in version 14.
    // For older Safari versions and some older browsers, we use 'addListener', which is a non-standard method.
    // Here, we try to use 'addEventListener' first, and if that throws an error, we then try 'addListener'.
    // The catch blocks handle any exceptions by logging the error to the console for debugging.
    try {
      menuBreakPoint.addEventListener("change", resetMenu);
    } catch (e1) {
      try {
        console.log(e1, "Trying addListener instead of addEventListener.");
        menuBreakPoint.addListener(resetMenu);
      } catch (e2) {
        console.log(e2);
      }
    }
  }
}
