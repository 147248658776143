/**
 * Checks if the `row-gap` property is supported in a flexbox context.
 * This is determined by creating a temporary flex container with a row-gap.
 * The container's scrollHeight is then checked to see if the row-gap has taken effect.
 * @returns {boolean} Returns `true` if `row-gap` is supported in flexbox, otherwise `false`.
 */
export default function checkFlexGap() {
    // create flex container with row-gap set
    var flex = document.createElement("div");
    flex.style.display = "flex";
    flex.style.flexDirection = "column";
    flex.style.rowGap = "1px";
  
    // create two child elements inside it
    flex.appendChild(document.createElement("div"));
    flex.appendChild(document.createElement("div"));
  
    // append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex);
    var isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
    flex.parentNode.removeChild(flex);
  
    return isSupported;
  }