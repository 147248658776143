export default function gliderJSinit() {
  if (document.querySelector(".glider")) {
    new Glider(document.querySelector(".glider"), {
      // mobile first defaults
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: true,
      scrollLock: true,
      dots: "#dots",
      arrows: {
        prev: ".glider-prev",
        next: ".glider-next",
      },
      responsive: [
        {
          // screens greater than >= 775px
          breakpoint: 944,
          settings: {
            // Set to `auto` and provide item width to adjust to viewport
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    });
  }
};
